.vm-ag-grid-container {
  height: 100%;
  width: 100%;
  min-height: 63vh;
  -webkit-font-smoothing: antialiased;
  /* color: #000; */
  /* color: var(--ag-foreground-color,#000); */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 12px;
  line-height: normal;
}

.vm-ag-grid-container a {
  text-decoration: underline;
}

.vm-ag-grid-container a:hover {
  text-decoration: none;
}

.vm-ag-grid-container .ag-header {
  border-bottom-width: 2px !important;
}

.vm-ag-grid-container .vm-grid-header::after {
  width: 0px !important;
}

.vm-ag-grid-container .vm-grid-header {
  /* background: #ffffff; */

  padding: 12px;
}

.vm-ag-grid-container .vm-icon-only .ag-header-cell-label {
  /*padding-left: 12px;*/
  /*margin-left: 8px;*/
}

.row-pass {
  font-weight: bold;
  background-color: #e0dedd !important;
}
.vm-ag-grid-container .vm-icon-only .ag-sort-descending-icon,
.vm-ag-grid-container .vm-icon-only .ag-sort-ascending-icon {
  margin-left: -14px;
}

.vm-ag-grid-container .ag-row.ag-row-focus {
  z-index: 1;
  padding-top: 1px;
}

.vm-ag-grid-container .vmm_flex_center {
  display: flex;
  align-items: center;
}

.vm-ag-grid-container .ag-focus-managed:focus .ag-cell-label-container {
  color: inherit !important;
  outline-color: inherit !important;
  outline-style: dashed !important;
  outline-width: 1px !important;
  outline-offset: 2px !important;
  border-color: inherit !important;
}

.vm-ag-grid-container .remove-expand .ag-group-expanded,
.vm-ag-grid-container .remove-expand .ag-group-contracted {
  display: none !important;
  visibility: hidden;
}

.vm-ag-grid-container .ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.vm-ag-grid-container .ag-cell:focus {
  color: inherit !important;
  outline-color: inherit !important;
  outline-style: dashed !important;
  outline-width: 1px !important;
  outline-offset: 2px !important;
  border-color: inherit !important;
}

.ag-row .ag-cell.vm-text-ellipsis {
  padding-top: 10px;
}

.vm-modal-table {
  border: none !important;
}

.vm-modal-table td,
.vm-modal-table th {
  margin-left: 0px !important;
}

.ag-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

/* .ag-root-wrapper.ag-layout-auto-height, */
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-center-cols-clipper {
  overflow: visible;
}

.vm-ag-grid-container .ag-row-selected {
  /* background-color: #f7f7f7; */
}

.duns-wrapper > div {
  height: 80vh !important;
}

/* .vm-ag-grid-container.duns-wrapper>div {
  height: 100vh;
} */

/*
.vm-ag-grid-container .C-Tooltip.--left:before{
  height: 15vh;
  width: 15vw;
  padding: 4px;
}
@media (max-width: 750px) {
  .vm-mobile .vm-ag-grid-container {
    min-width: 1200px;
  }
}
*/
/*
.actions-button-cell {
  overflow:visible;
}

.ag-cell {
  overflow:visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.ag-root,
.ag-root-wrapper,
.ag-body-viewport,
.ag-body-viewport-wrapper,
.ag-center-cols-clipper,
.ag-center-cols-viewport {
  overflow: visible !important;
}
 */
